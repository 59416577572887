import { useState } from "react";

import {
  Stack,
  Grid,
  Paper,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Create,
  Box,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@pankod/refine-mui";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ImageUploading, { ImageListType } from "react-images-uploading";

import { useForm } from "@pankod/refine-react-hook-form";
import { PopoverPicker } from "./sketchsample";
import {
  IResourceComponentsProps,
  HttpError,
  useDataProvider,
  useCustom,
} from "@pankod/refine-core";
import "react-mde/lib/styles/css/react-mde-all.css";

import { ICompany } from "interfaces";
import moment from "moment";

interface AppProps {
  onChange?: (arg0: string) => void;
  title: string;
  defaultValue?: string;
} /* use `interface` if exporting so that consumers can extend */

export interface ResponseAPI {
  success: boolean;
  message: string[];
  data: ResponseData;
}

export interface ResponseData {
  created_at: string;
  updated_at: string;
  file_type: string;
  name: string;
  file_url: string;
  mime: string;
  md5: string;
  size: number;
  direct_url: string;
  company_id: string;
  is_auth_required: boolean;
  access_group: any;
  access_level: any;
  skill_competence: any;
  related_course_id: any;
  user_id: string;
  is_learning_material: boolean;
}

const ImageComponent = (props: AppProps) => {
  const dataProvider = useDataProvider();
  const { create } = dataProvider("imagedataprovider");

  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const onUploadValue = async (imageList: ImageListType) => {
    try {
      const res = await create<ResponseAPI>({
        resource: "test",
        variables: { file: imageList[0].file },
      });
      setImages(imageList as never[]);
      props.onChange && props.onChange(res.data.data.file_url!);
    } catch (e) {
      console.log("Error uploading");
    }
  };

  return (
    <ImageUploading
      value={images}
      onChange={onUploadValue}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      acceptType={["jpg", "jpeg", "png", "gif"]}
    >
      {({ imageList, onImageUpload }) => (
        <>
          {/* <Stack direction="column" alignItems="center" spacing={1}> */}
          {/* <Stack direction="row" alignItems="center" spacing={1}> */}
          {/* </Stack> */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={2}
            flexDirection="column"
            onClick={onImageUpload}
          >
            <Typography variant="body2" fontWeight="medium" gutterBottom>
              {props.title}{" "}
            </Typography>
            {/* {imageList.length > 0  &&  <Chip onClick={onImageUpload} size="small" variant="outlined" label="update" color="primary" /> }             */}

            {imageList.length > 0 ? (
              <Box
                component="img"
                boxShadow={1}
                sx={{
                  width: 100,
                  height: 100,
                  // maxHeight: { xs: 150, md: 150 },
                  // maxWidth: { xs: 100, md: 100 },
                }}
                src={imageList[0].data_url}
              />
            ) : (
              <Box
                // component="div"
                display="flex"
                boxShadow={1}
                sx={{
                  width: 100,
                  height: 100,
                  // maxHeight: { xs: 150, md: 150 },
                  // maxWidth: { xs: 100, md: 100 },
                  // p: 2,
                  // border: "1px dashed grey",
                }}
                justifyContent="center"
                alignItems="center"
              >
                {/* <Stack> */}
                <Button onClick={onImageUpload} size="small" variant="outlined">
                  Upload
                </Button>
                {/* </Stack> */}
              </Box>
            )}
          </Box>
          {/* </Stack> */}
          {/* </Box> */}
        </>
      )}
    </ImageUploading>
  );
};

export const CreateCompanyMUI: React.FC<IResourceComponentsProps> = () => {
  const {
    register,
    refineCore: { formLoading },
    saveButtonProps,
    setValue,
    getValues,
  } = useForm<ICompany, HttpError, ICompany>({
    refineCoreProps: {
      dataProviderName: "default",
      redirect: "edit",
    },
    defaultValues: {
      learninghub: {
        domain: "https://codemi.learninghub.co.id",
      },
    },
  });

  const [startDate, setStartDate] = useState(new Date());
  const [customerType, setCustomerType] = useState<any>("");
  const [subscribed, setSubscribed] = useState<any>(false);
  const [status, setStatus] = useState<any>(true);

  const planData: any = useCustom({
    url: `company/plan`,
    method: "get",
    dataProviderName: "default",
  });
  const planList = planData?.data?.data.data;
  const subscribedPlan = [
    {
      name: "Subscribed",
      slug: true,
    },
    {
      name: "Not Subscribed",
      slug: false,
    },
  ];

  return (
    <Create
      breadcrumb={false}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, disabled: customerType === "" }}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Paper elevation={2}>
              <Card variant="outlined">
                <CardHeader title="Company Info" disableTypography={true} />
                <CardContent>
                  <Grid container>
                    <Grid xs={12} sm={12} md={6} padding={"1em"}>
                      <Stack spacing={3} direction="column">
                        <TextField
                          {...register("name")}
                          size="small"
                          id="name"
                          label="Name"
                          variant="outlined"
                          fullWidth={true}
                        />
                        <TextField
                          {...register("email")}
                          id="email"
                          label="Email"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                        />
                        <TextField
                          {...register("phone")}
                          id="phone"
                          label="Phone"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                        />
                        <div
                          style={{
                            border: "1px solid #626262",
                            borderRadius: "4px",
                          }}
                        >
                          <DatePicker
                            className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall css-1hz192s-MuiInputBase-input-MuiOutlinedInput-input"
                            selected={startDate}
                            onChange={(date: any) => {
                              setValue(
                                "expired_at",
                                moment(date)
                                  .set({
                                    hour: 23,
                                    minute: 59,
                                    second: 59,
                                  })
                                  .format()
                              );
                              setStartDate(date);
                            }}
                            placeholderText="Expired Date"
                          />
                        </div>
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} padding={"1em"}>
                      <Stack spacing={3} direction="column">
                        <TextField
                          {...register("domain")}
                          id="domain"
                          label="Domain"
                          variant="outlined"
                          defaultValue={"https://"}
                          fullWidth={true}
                          size="small"
                        />
                        <TextField
                          {...register("page_title")}
                          id="page_title"
                          label="Page Title"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                        />
                        <TextField
                          id="sub_title"
                          label="Sub Title"
                          defaultValue={""}
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                        />
                        <TextField
                          {...register("codemi_cs")}
                          id="codemi_cs"
                          label="CS Number"
                          variant="outlined"
                          defaultValue={"https://wa.me"}
                          fullWidth={true}
                          size="small"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>

          <Grid item md={12}>
            <Paper elevation={2}>
              <Card variant="outlined">
                <Grid xs={12} sm={12} md={12} padding={"1em"}>
                  <FormControlLabel
                    style={{ marginBottom: "1em" }}
                    control={
                      <Switch
                        {...register("learninghub.status")}
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                    }
                    label="Learning Hub"
                  />
                  <Stack spacing={3} direction="column">
                    <TextField
                      {...register("learninghub.domain")}
                      id="domain_learninghub"
                      label="Domain Learning Hub"
                      variant="outlined"
                      defaultValue={"https://codemi.learninghub.co.id"}
                      fullWidth={true}
                      style={{ marginBottom: "1em" }}
                      size="small"
                    />
                  </Stack>
                  <Stack spacing={3} direction="column">
                    <span>Status Subscribed</span>
                    <Select
                      {...register("learninghub.subscribed")}
                      sx={{ minWidth: "30vw" }}
                      id="plan-select"
                      value={subscribed}
                      onChange={(e) => setSubscribed(e.target.value)}
                    >
                      {subscribedPlan.map((plan: any) => (
                        <MenuItem value={plan.slug}>{plan.name}</MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              </Card>
            </Paper>
          </Grid>

          <Grid item md={12}>
            <Paper elevation={2}>
              <Card variant="outlined">
                <CardHeader title="Admin Info" disableTypography={true} />
                <CardContent>
                  <Grid container>
                    <Grid xs={12} sm={12} md={6} padding={"1em"}>
                      <Stack spacing={3} direction="column">
                        <TextField
                          size="small"
                          id="name"
                          {...register("admin.name")}
                          label="Name"
                          variant="outlined"
                          fullWidth={true}
                        />
                        <TextField
                          id="email"
                          label="Email"
                          {...register("admin.email")}
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} padding={"1em"}>
                      <Stack spacing={3} direction="column">
                        <TextField
                          id="username"
                          {...register("admin.username")}
                          label="Username"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                        />
                        <TextField
                          id="password"
                          {...register("admin.password")}
                          label="Password"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>

          <Grid item md={6}>
            <Paper elevation={2}>
              <Card variant="outlined">
                <CardHeader
                  fontWeight="bold"
                  title="Assets"
                  disableTypography={true}
                />
                {/* <CardContent> */}
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={3} padding={0}>
                    <ImageComponent
                      title="Logo"
                      defaultValue={getValues("logo")}
                      onChange={(file: string) => {
                        setValue("logo", file);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} padding={"0em"}>
                    <ImageComponent
                      title="Icon"
                      onChange={(file: string) => {
                        setValue("icon", file);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} padding={"0em"}>
                    <ImageComponent
                      title="Favicon"
                      onChange={(file: string) => {
                        setValue("favicon", file);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} padding={"0em"}>
                    <ImageComponent
                      title="User"
                      onChange={(file: string) => {
                        setValue("user_picture", file);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} padding={"0em"}>
                    <ImageComponent
                      title="Background"
                      onChange={(file: string) => {
                        setValue("background", file);
                      }}
                    />
                  </Grid>
                </Grid>
                {/* </CardContent> */}
              </Card>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={2}>
              <Card variant="outlined">
                <CardHeader title="Colors" disableTypography={true} />
                <CardContent>
                  <Grid container>
                    <Stack direction={"column"}>
                      <Typography
                        variant="body2"
                        fontWeight="medium"
                        gutterBottom
                      >
                        Accent Color
                      </Typography>
                      <Picker
                        onChange={(color: any) =>
                          setValue("accent_color", color)
                        }
                      />
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
            <Paper elevation={2} style={{ marginTop: "0.6vh" }}>
              <Card variant="outlined">
                <CardHeader title="Plan" disableTypography={true} />
                <CardContent>
                  <Grid container>
                    <Stack direction={"column"}>
                      <Grid container spacing={1}>
                        <Select
                          sx={{ minWidth: "30vw" }}
                          id="plan-select"
                          {...register("customer_type")}
                          onChange={(e) => setCustomerType(e)}
                        >
                          {planList?.map((plan: any) => (
                            <MenuItem value={plan.slug}>{plan.name}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </Create>
  );
};

interface PickerProps {
  onChange: (arg0: any) => void;
} /* use `interface` if exporting so that consumers can extend */

const Picker = (props: PickerProps) => {
  const [color, setColor] = useState();
  return (
    <PopoverPicker
      color={color}
      onChange={(c: any) => {
        setColor(c["hex"]);
        props.onChange(c["hex"]);
      }}
    />
  );
};
